import React, { useEffect, useState } from "react"
import { animateLetters } from "../../lib/helperFunctions.js"
import charming from "charming"

const AnimationTitle = ({ children, delay, initDelay, duration }) => {
  const [spansConverted, setSpansConverted] = useState(false)
  const convertToSpans = () => {
    const targets = document.querySelectorAll(".text-animated")
    if (targets) {
      for (const t of targets) {
        charming(t)
        t.querySelector(".line-inner").classList.add("is-animating")
      }
    }

    setSpansConverted(true)
  }

  useEffect(() => {
    convertToSpans()
  }, [])

  useEffect(() => {
    const targets = [...document.querySelectorAll(".line-inner > span")]

    animateLetters(targets, initDelay, delay, duration)
  }, [spansConverted])
  return <React.Fragment>{children}</React.Fragment>
}

export default AnimationTitle
