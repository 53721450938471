import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle r={25} transform="matrix(-1 0 0 1 25 25)" fill="#fff" />
      <path
        d="M17.514 28.326l7.745 6.374 7.746-6.374M25.37 34.7V15"
        stroke="#000"
      />
    </svg>
  )
}

export default SvgComponent
