import React, { useEffect, useContext, useRef, useState } from "react"
import styled from "styled-components"
import { useTransition, animated } from "react-spring"
import { pageMargins, remCalc, absoluteMargins } from "../styles/utils"
import { animateParagraph, elasticScaleIn } from "../../lib/helperFunctions"
import Explore from "../images/svgs/Explore"
import LetterAnimation from "../animations/LetterAnimation"
import MapMobile from "../images/svg/Map.svg"
import Lottie from "./Lottie"
import Img from "gatsby-image"
import Triangle from "../images/svgs/Triangles"
import useIsTablet from "../hooks/useIsTablet"
import { absoluteMarginsRight } from "../styles/utils"

const StyledHero = styled.section`
  width: 100%;
  background-color: ${({ bg }) => bg};
  height: 100vh;
  min-height: 30rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${pageMargins};
  padding-top: 6rem;
  overflow: hidden;
  position: relative;

  & .map__zoom {
    width: ${remCalc(480)};
    float: right;
  }
  & .map-mobile {
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    ${absoluteMarginsRight};
    @media only screen and (max-width: 640px) {
      display: none;
    }
    @media only screen and (min-width: 1024px) {
      display: none;
    }
  }
  & .landing_para {
    margin-top: 1.8rem;
    max-width: 22rem;
    @media only screen and (max-width: 640px) {
      max-width: 225px;
    }
  }

  & .top_triangle {
    width: 0;
    height: 0;
    border-left: ${remCalc(228)} solid transparent;
    border-right: ${({ headerColor }) =>
      `${remCalc(228)} solid ${headerColor}`};
    border-bottom: ${remCalc(228)} solid transparent;
    top: 0;
    z-index: 1;
    position: absolute;
    right: 0;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  & .hero_title {
    &.landing_title {
      width: 100%;
    }
    @media only screen and (min-width: 1024px) {
      font-size: 4.2rem;
      line-height: 4.2rem;
      min-width: ${remCalc(600)};
      &.landing_title {
        font-size: ${remCalc(88)};
        line-height: ${remCalc(105)};
      }
    }
    @media only screen and (min-width: 1440px) {
      font-size: 5rem;
      line-height: 5rem;
    }
    @media only screen and (max-width: 1024px) {
      font-size: ${({ economic }) => (economic ? "51px" : "64px")};
      line-height: ${({ economic }) => (economic ? "62px" : "68px")};
      width: ${({ economic }) => (economic ? "50%" : "100%")};
      &.landing_title {
        font-size: 64px;
        line-height: 83px;
      }
    }
    @media only screen and (max-width: 640px) {
      font-size: 40px;
      line-height: 56px;
      width: 320px;
      &.landing_title {
        width: 288px;
        font-size: 40px;
        line-height: 56px;
      }
    }
  }

  & * {
    color: ${({ economic }) => (economic ? "black" : "white")};
  }
  & .content_section {
    width: 50%;
    display: flex;
    align-items: flex-start;

    flex-direction: column;
    position: relative;
    padding-bottom: 6rem;

    @media only screen and (max-width: 1024px) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50%;
      justify-content: center;

      padding-bottom: 0;
      ${pageMargins};
    }
    &.foreword {
      width: 100%;
      height: 100vh;
      justify-content: center;
      ${({ economic }) =>
        economic
          ? `
          height: unset;
          @media only screen and (max-width: 1024px) and (min-width: 640px) {
             height: 100vh;
          }
      @media only screen and (max-width: 640px) {
        height: 50vh;
      }
      `
          : ""}
    }
    &.economic {
    }
  }

  & h1 {
    width: 100%;
    margin-bottom: 0rem;
  }
  & p {
    width: 80%;
    line-height: ${remCalc(34)};
    @media only screen and (max-width: 640px) {
      font-size: 18px;
      line-height: 27px;
    }
  }
  & .explore_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    position: absolute;
    top: 78vh;
    ${absoluteMargins};
    &.desktop {
      display: flex;
    }
    &.mobile {
      display: none;
    }
    @media only screen and (max-width: 1024px) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
      top: unset;
      bottom: ${({ noFullHeight }) => (noFullHeight ? "1rem" : "5rem")};
    }
  }

  & .circle__container {
    cursor: pointer;

    & svg {
      opacity: 0;
      margin-right: 2rem;
    }
    &-mobile {
      & svg {
        opacity: 0;
        margin-right: 2rem;
      }
    }
  }

  & .paragraph-transform {
    & span {
      display: inline-block;
      transition-timing-function: cubic-bezier(0.29, 0.09, 0, 0.99);
      transform: translateY(100%);
    }
    &.is-animating {
      & span {
        transform: translateY(0%);
        transition-timing-function: cubic-bezier(0.29, 0.09, 0, 0.99);
      }
    }
  }

  & .triangle-svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  & .spotlight_para {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    @media only screen and (max-width: 1024px) {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 640px) {
      margin-bottom: 0rem;
    }
  }
  & .background_section {
    width: 50%;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;

    & .gatsby-image-wrapper {
      height: 100%;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      top: unset;
    }
  }
  @media only screen and (max-width: 1024px) {
    justify-content: flex-start;
    height: 100vh;
    min-height: ${({ spotlightOn }) => (spotlightOn ? "760px" : "600px")};
    & .explore_container {
      margin-top: 0;
    }

    &.landing_content {
      height: var(--content-height);
      & .content_section {
        height: 100%;
      }
    }
  }

  ${({ economic }) =>
    economic
      ? `
      @media only screen and (max-width: 640px) {
        height: 50vh;
        min-height: 288px;
        & .content_section {
          padding-top: 4rem;
          & .explore_container {
            display: none;
          }
        }
      }
      `
      : ""}
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    height: ${({ noFullHeight }) => (noFullHeight ? "1000px" : "100vh")};
  }
  @media only screen and (max-width: 640px) {
    height: ${({ noFullHeight, economic }) =>
      economic ? "50vh" : noFullHeight ? "823px" : "100vh"};
  }
`

const ExploreIcon = ({ device, location }) => {
  return (
    <React.Fragment>
      {!location.includes("/contact") ? (
        <div
          data-scroll
          data-scroll-speed="1"
          className={`explore_container ${device}`}
        >
          <div
            className={`circle__container${
              device === "mobile" ? "-mobile" : ""
            }`}
          >
            <Explore className="svg_explore" />
          </div>
          <p
            className="paragraph-transform"
            data-delay={2.2}
            data-duration={0.5}
          >
            <span className="line-inner">
              {!location.includes("/economic-contribution")
                ? "Explore"
                : "Scroll or select data point on map"}
            </span>
          </p>
        </div>
      ) : null}
    </React.Fragment>
  )
}

const Hero = ({
  bg,
  bgImage,
  children,
  economic = null,
  animationData,
  mobileAnimationData,
  mobileColors = null,
  location,
  headerColor,
  contact,
}) => {
  const [show, set] = useState(false)
  const transitions = useTransition(show, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 2000 },
  })
  const isTablet = useIsTablet()
  const spotlightOn =
    !contact &&
    location.length > 1 &&
    !location.includes("/economic-contribution") &&
    location !== "/foreword" &&
    location !== "/landsec-contribution-overview" &&
    !location.includes("/covid-19-response")

  const noFullHeight =
    !location.includes("/economic-contribution") &&
    location !== "/foreword" &&
    !contact &&
    location.length > 1

  useEffect(() => {
    animateParagraph()

    elasticScaleIn({ selector: ".svg_explore", delay: 1.2, duration: 0.7 })
    set(true)
    return () => {
      set(false)
    }
  }, [])

  return (
    <StyledHero
      bg={bg}
      spotlightOn={spotlightOn}
      economic={economic}
      noFullHeight={noFullHeight}
      className={`${location.length < 2 ? "landing_content" : ""} hero_section`}
      headerColor={headerColor ? headerColor : "#000000"}
    >
      {economic ? <MapMobile className="map-mobile" /> : ""}
      {!economic ? <div className="top_triangle" /> : ""}
      {bgImage ? (
        <div className="background_section">
          <Img fluid={bgImage.childImageSharp.fluid} />
        </div>
      ) : (
        ""
      )}
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              {!isTablet ? <Lottie animationData={animationData} /> : ""}
              {isTablet ? <Lottie animationData={mobileAnimationData} /> : ""}
            </animated.div>
          )
      )}

      <div
        className={`content_section ${
          location.includes("/contact") ? "contact" : ""
        } ${
          location.includes("/foreword") ||
          location.includes("/economic-contribution") ||
          location.includes("/contact") ||
          location === "/"
            ? "foreword"
            : ""
        }`}
        data-scroll-speed="2"
      >
        {spotlightOn ? <p className="spotlight_para">Spotlight on:</p> : ""}
        {children}
        <ExploreIcon device="mobile" location={location} />
      </div>

      <ExploreIcon device="desktop" location={location} />
    </StyledHero>
  )
}

export default Hero
