import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={360}
      height={437}
      viewBox="0 0 360 437"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M359.567 171.441h-57.143v-57.143l57.143 57.143z"
        fill={props.color_1}
      />
      <path
        d="M246.306 437h-57.143v-57.143L246.306 437zM0 114h57.143v57.143L0 114z"
        fill="#fff"
      />
      <path d="M303 323h57v57l-57-57z" fill={props.color_1} />
      <path d="M303 379.851h57.144v57.143L303 379.851z" fill={props.color_2} />
      <path
        d="M360.292 114.292h-57.144V57.149l57.144 57.143zM246.143 114.292H189V57.149l57.143 57.143z"
        fill={props.color_3}
      />
      <path d="M303.143 57.143V0H246l57.143 57.143z" fill={props.color_1} />
      <path
        d="M246 114.143h57.143V57L246 114.143zM303.143 436.994v-57.143H246l57.143 57.143z"
        fill={props.color_1}
      />
      <path
        d="M303 322.708v57.143h57.144L303 322.708zM0 57v57.143h57.143L0 57zM303 209v57.143h57.143L303 209z"
        fill={props.color_3}
      />
      <path d="M303.148 0v57.143h57.144L303.148 0z" fill="#fff" />
    </svg>
  )
}

export default SvgComponent
